@mixin skiplink-configs {
  @include skiplink-tokens;
  @include skiplink-default;
  @include skiplink-full;
}

@mixin skiplink-tokens {
  --skiplink-layer: var(--z-index-layer-4);
  --skiplink-margin: var(--spacing-scale-2x);
  --skiplink-min-width: 300px;
  --skiplink-shadow: var(--surface-shadow-lg);
  --skiplink-duration: 150ms;
  --skiplink-timing-function: ease-out;
}

@mixin skiplink-default {
  display: inline-flex;
  flex-direction: column;
  left: var(--skiplink-margin);
  position: fixed;
  top: 0;
  z-index: var(--skiplink-layer);

  a {
    align-items: center;
    background-color: var(--background);
    box-shadow: var(--skiplink-shadow);
    display: inline-flex;
    min-width: var(--skiplink-min-width);
    position: absolute;
    top: -100vh;
    transition: top var(--skiplink-duration) var(--skiplink-timing-function);
    white-space: nowrap;
    width: auto;

    &:focus,
    &:focus-visible {
      top: 0;
    }
  }

  & .#{$prefix}tag {
    background: var(--interactive);
  }

}

@mixin skiplink-full {
  &.full {
    box-shadow: var(--skiplink-shadow);
    flex-direction: row;
    top: -100vh;
    transition: top var(--skiplink-duration) var(--skiplink-timing-function);

    a {
      box-shadow: none;
      min-width: 0;
      position: relative;
      top: 0;
    }

    &:focus-within {
      top: 0;
    }
  }
}
